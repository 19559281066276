/**
 * QR payment for UPI
 */
import { checkIfObjectExists } from '@/utils/checkIfObjectExists';
import { PAYMENT_TYPE_CONSTANTS } from '@/utils/constants';
import { getDeviceInfo } from '@/utils/deviceInfo';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import {
  initPaymentForQR,
  prePaymentCheckMDRTransactions,
  pollPayment,
  savePaymentInfo,
} from 'src/redux/actions';
import { toastNotifyError } from '../Common/Toast';

const initialState = {
  otp: '',
  minutes: 3,
  seconds: 0,
  isTimeUp: false,
  qrText: '',
  showGenerateQr: true,
};

const QRPaymentUPI = (props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  let timerID = null;
  const [state, setState] = useState(initialState);
  const [isTimerStarted, setIsTimerStarted] = useState(false);

  const { orderDetails, storeInfo, loader, userData } = useSelector((state) => ({
    orderDetails: state.orderReducer.orderDetails,
    storeInfo: state.storeReducer.store,
    loader: state.commonReducer.loader,
    userData: state.userReducer.data,
  }));

  const checkIfMDRActive = storeInfo?.services?.mdr_flag === 1;

  useEffect(() => {
    setState(initialState);

    return () => {
      setIsTimerStarted(false);
    };
  }, [props.partial_payment]);

  // useEffect for timer
  useEffect(() => {
    if (isTimerStarted) {
      timerID = setInterval(() => {
        _timer(state);
      }, 1000);
    }

    return () => {
      clearTimer();
    };
  }, [state.minutes, state.seconds, isTimerStarted]);

  const clearTimer = () => {
    timerID && clearInterval(timerID);
  };

  /**
   * Function update the state for timer
   * @param {Object} state
   */
  const _timer = (state) => {
    if (state.seconds > 0) {
      setState((preState) => ({
        ...preState,
        seconds: preState.seconds - 1,
      }));
    }
    if (state.seconds === 0) {
      if (state.minutes === 0) {
        clearTimer();
        setState((preState) => ({
          ...preState,
          isTimeUp: true,
        }));
      } else {
        setState((preState) => ({
          ...preState,
          minutes: preState.minutes - 1,
          seconds: 59,
        }));
      }
    }
  };

  const initiatePayment = (payload) => {
    setIsTimerStarted(true);
    setState((state) => ({
      ...state,
      showGenerateQr: false,
    }));
    // Intiate payment
    dispatch(
      initPaymentForQR(payload, (_intPay) => {
        // If payment initiated successfully
        if (_intPay.status) {
          setState((state) => ({
            ...state,
            minutes: 3,
            seconds: 0,
            isTimeUp: false,
            showGenerateQr: false,
            qrText: _intPay.intentLink,
          }));

          // Initiate the payment varification process
          if (_intPay.transactionId) {
            dispatch(pollPayment(_intPay.transactionId, router));
          }
        } else {
          setState(initialState);
          clearTimer();
          toastNotifyError(`error: ${_intPay?.message || 'Unable to initiate payment!'}`);
        }
      })
    );
    localStorage?.setItem('orderID', orderDetails?.order_hash);
  };

  /**
   * Function to initiate a payment and
   * set the state with the new QR data
   */
  const createNewQR = () => {
    const payload = {
      orderID: orderDetails?.order_hash,
      paymentMethod: PAYMENT_TYPE_CONSTANTS.DUPI,
      merchantID: orderDetails?.merchant_id,
      deviceType: getDeviceInfo().OSName,
      browserType: getDeviceInfo().BrowserName,
      userAgent: getDeviceInfo().userAgent,
      phone: userData?.phone,
      source: 'longtail',
      merchantName: `Digital Showroom - ${storeInfo?.store_info?.name}`,
    };

    dispatch(savePaymentInfo(payload));

    if (checkIfMDRActive && checkIfObjectExists(props.txnData)) {
      const mdrPld = {
        order_hash: orderDetails?.order_hash,
        store_id: orderDetails?.store_id,
        payment_option: props.txnData,
      };
      dispatch(
        prePaymentCheckMDRTransactions(mdrPld, () => {
          setTimeout(() => initiatePayment(payload), 100);
        })
      );
    } else {
      initiatePayment(payload);
    }
  };

  const QrPlaceholder = ({ children, classNameExtension }) => {
    return (
      <div className="relative">
        <QRCode size={150} value={''} />
        <div
          className={`bg-black-80 absolute w-100 top-0 left-0 right-0 bottom-0  flex justify-center items-center ${classNameExtension}`}
        >
          {children}
        </div>
      </div>
    );
  };

  const _renderQR = () => {
    if (state.isTimeUp) {
      return (
        <QrPlaceholder classNameExtension={'bg-black-90'}>
          <span
            className="flex justify-center items-center pb2 fw6 f5 white "
            onClick={createNewQR}
          >
            <img
              src="/assets/images/twotone_refresh_white_24dp.png"
              className="mr2 img h2"
            />
            Refresh
          </span>
        </QrPlaceholder>
      );
    }

    if (state.showGenerateQr) {
      return (
        <QrPlaceholder classNameExtension={'bg-black-70'}>
          <button
            style={{ cursor: 'pointer' }}
            className="pa1 bg-white br2 bw0"
            onClick={createNewQR}
          >
            Generate Code
          </button>
        </QrPlaceholder>
      );
    }

    return state.qrText && !loader ? (
      <QRCode size={150} value={state.qrText} />
    ) : (
      <QrPlaceholder classNameExtension={'bg-black-70'}>
        <img src="/assets/images/ellipLoader1.gif" className="h2 img" />
      </QrPlaceholder>
    );
  };

  const _renderQrTimerMessage = () => {
    return state.isTimeUp ? (
      <>
        <p className="f7 fw3">This QR code has expired</p>
      </>
    ) : (
      <p className="f7 fw3">
        This QR code will expire in 0{state.minutes}:
        {state.seconds < 10 ? `0${state.seconds}` : state.seconds} Minutes
      </p>
    );
  };

  return (
    <div className="ph4 pt4 pb3 flex flex-column flex-auto">
      <section className="flex flex-column justify-center items-center">
        {_renderQR()}
        {isTimerStarted ? _renderQrTimerMessage() : <div className="mb4" />}
      </section>
      <section className="ba b--black-10 pv3 ph4 br3">
        <article className="tc fw5 f5 pb2 lh-copy">
          Scan this QR code with any of your UPI App and pay.
        </article>
        <article className="flex justify-center items-center">
          <img src="/assets/images/ic_paytm@2x.png" className="img h2" />
          <img src="/assets/images/ic_gpay@2x.png" className="img h2 mh3" />
          <img src="/assets/images/ic_phonep@2x.png" className="img h2" />
        </article>
      </section>
    </div>
  );
};

export default QRPaymentUPI;
