import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';

const useUnselectablePaymentOptions = () => {
  const { unselectablePaymentOptionsWithTxnCharge, unselectablePaymentOptions } =
    useSelector((state: RootState) => ({
      unselectablePaymentOptionsWithTxnCharge:
        state.paymentReducer.paymentOptionsWithTxnCharge?.grayed_out_payment_options ||
        [],
      unselectablePaymentOptions: state.paymentReducer.unselectablePaymentOptions || [],
    }));

  const getUnselectablePaymentOptionsToDisplay = () => {
    return unselectablePaymentOptions?.length
      ? unselectablePaymentOptions
      : unselectablePaymentOptionsWithTxnCharge?.length
        ? unselectablePaymentOptionsWithTxnCharge?.map(
            (paymentOption) => paymentOption?.payment_option_name
          )
        : [];
  };

  return {
    finalUnselectablePaymentOptions: getUnselectablePaymentOptionsToDisplay(),
  };
};

export default useUnselectablePaymentOptions;
