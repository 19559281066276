import { MINIMUM_ORDER_AMOUNT, PREPAID_POSTPAID_METHODS } from './constants';
import store from '../redux/store';

export function checkForInvalidAmount() {
  const storeInfo = store.getState().storeReducer.store;
  const orderDetails = store.getState().orderReducer.orderDetails;
  if (
    (storeInfo?.services?.payment_method === PREPAID_POSTPAID_METHODS.BOTH ||
      storeInfo?.services?.payment_method === PREPAID_POSTPAID_METHODS.POSTPAID) &&
    orderDetails?.buyer_pay_value < MINIMUM_ORDER_AMOUNT
  ) {
    return true;
  }
  return false;
}
