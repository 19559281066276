const BTN_STYLES = {
  backgroundColor: '#0C4BFF',
  padding: '14px',
  lineHeight: '24px',
  borderRadius: 12,
};

const MobikwikPayment = ({ onClick }) => {
  return (
    <div className="pa4 flex flex-column items-center">
      <img
        src={`https://cdn.dotpe.in/kiranaStatic/icons/mobikwik_icon.png`}
        width="50%"
        className="mx-auto"
      />
      <button
        className="w-100 white fw7 ttu f18px bn mt5 pointer"
        style={BTN_STYLES}
        onClick={onClick}
      >
        Pay using Mobikwik
      </button>
    </div>
  );
};

export default MobikwikPayment;
