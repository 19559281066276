import store from '../redux/store';

export function isCodDisabledForOrder() {
  const paymentReducer = store.getState().paymentReducer;
  if (paymentReducer?.otherMethods?.length) {
    return !paymentReducer?.otherMethods.some((method) => method === 'cod');
  } else {
    return paymentReducer?.paymentOptionsWithTxnCharge?.others
      ? !paymentReducer?.paymentOptionsWithTxnCharge?.others?.some(
          (method) => method?.payment_option_name === 'cod'
        )
      : true;
  }
}
